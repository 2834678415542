
import {defineComponent, onMounted, ref, watch} from 'vue';
import {useRoute} from 'vue-router'
import DefaultIcon from "@/components/Icon/DefaultIcon.vue";
import ConfirmDialog from "@/components/Modal/ConfirmDialog.vue";
import DeleteConfirmDialog from "@/components/Modal/DeleteConfirmDialog.vue";

export default defineComponent({
	name: 'SidebarItem',
	components: {DeleteConfirmDialog, ConfirmDialog, DefaultIcon},
	props: {
		itemText: {
			type: String,
			default: ""
		},
		itemIconPre: {
			type: String,
			default: ""
		},
		itemIconAfter: {
			type: String,
      default: "",
    },
    itemWidth: {
      type: String,
      default: "",
    },
    itemHeight: {
      type: String,
      default: "",
    },
    textHeight: {
      type: String,
      default: "text-sm",
    },
    link: {
      type: String,
      default: "",
    },
    routeName: {
      type: String,
      default: "",
    },
    showItem: {
      type: Boolean,
			default: true,
		},
		cssClass: {
			type: String,
			default: "",
		},
		enableConfirmDialog: {
			type: Boolean,
			default: false,
		},
		enableDeleteDialog: {
			type: Boolean,
			default: false,
		},
		confirmTitle: {
			type: String,
			default: ""
		},
		confirmDescription: {
			type: String,
			default: ""
		},
		deleteConfirmTitle: {
			type: String,
			default: ""
		},
		deleteConfirmDescription: {
			type: String,
			default: ""
		},
	},
	emits: ['confirmDialog', 'deleteConfirmDialog'],
	setup(props, {emit}) {
		const route = useRoute();
		const redirectLink = ref(props.link);
		const activeRoute = ref();
		const confirmRef = ref<InstanceType<typeof ConfirmDialog>>();
		const deleteConfirmRef = ref<InstanceType<typeof DeleteConfirmDialog>>();

		function isRouteActive(): boolean {
			if (props.routeName.length > 0) {
				return route.name === props.routeName;
			} else {
				return route.path === props.link;
			}
		}

		const toggleConfirmDialog = () => {
			confirmRef.value?.toggleDialog();
		}

		const toggleDeleteConfirmDialog = () => {
			deleteConfirmRef.value?.toggleDialog();
		}

		const confirmDialog = () => {
			emit('confirmDialog');
			toggleConfirmDialog;
		};

		const deleteConfirmDialog = () => {
			emit('deleteConfirmDialog');
			toggleDeleteConfirmDialog;
		};

		onMounted(() => {
			activeRoute.value = isRouteActive();
		})

		// eslint-disable-next-line
		watch(route, (route, prevRoute) => {
			activeRoute.value = isRouteActive();
		})

		return {
			confirmRef, deleteConfirmRef, redirectLink, activeRoute, toggleConfirmDialog, toggleDeleteConfirmDialog, confirmDialog, deleteConfirmDialog
		};
	}
})
