import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "z-10 w-auto md:w-max sticky top-20 md:top-24 md:grid md:grid-flow-col md:mr-4" }
const _hoisted_2 = { class: "md:col-span-4 w-full absolute md:relative md:max-w-xs lg:w-60 text-gray-700 dark:bg-gray-900 bg-white\n    rounded-3xl shadow-lg" }
const _hoisted_3 = { class: "flex-shrink-0 px-6 py-3 flex flex-row items-center justify-between" }
const _hoisted_4 = { class: "text-2xl font-semibold tracking-widest bp-font rounded-lg\n        dark-mode:text-white focus:outline-none focus:shadow-outline" }
const _hoisted_5 = { class: "rounded-lg md:hidden rounded-lg focus:outline-none focus:shadow-outline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultIcon = _resolveComponent("DefaultIcon")
  const _component_Default_icon = _resolveComponent("Default-icon")
  const _component_SidebarItem = _resolveComponent("SidebarItem")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("a", _hoisted_4, _toDisplayString(_ctx.$t("nav.Sidebar.AboutTitle")), 1),
        _createVNode("button", _hoisted_5, [
          _withDirectives(_createVNode(_component_DefaultIcon, {
            icon: "menu_open_icon",
            "icon-color": "red",
            onClick: _ctx.open
          }, null, 8, ["onClick"]), [
            [_vShow, !_ctx.showMenu]
          ]),
          _withDirectives(_createVNode(_component_Default_icon, {
            icon: "menu_close_icon",
            "icon-color": "red",
            onClick: _ctx.close
          }, null, 8, ["onClick"]), [
            [_vShow, _ctx.showMenu]
          ])
        ])
      ]),
      _createVNode("nav", {
        class: ["flex-grow md:block px-4 pb-4 md:pb-4\n      md:overflow-y-auto", {'hidden': !_ctx.showMenu, '': _ctx.showMenu}]
      }, [
        _createVNode(_component_SidebarItem, {
          "item-text": _ctx.$t('nav.Sidebar.HowTo'),
          "css-class": "flex-1",
          link: "/how-to",
          "text-height": "text-xl sm:text-md"
        }, null, 8, ["item-text"]),
        _createVNode(_component_SidebarItem, {
          "item-text": _ctx.$t('nav.Sidebar.ContentGuide'),
          "css-class": "flex-1",
          link: "/content-guide",
          "text-height": "text-xl sm:text-md"
        }, null, 8, ["item-text"]),
        _createVNode(_component_SidebarItem, {
          "item-text": _ctx.$t('nav.Sidebar.DevGuide'),
          "css-class": "flex-1 mb-5",
          link: "/developer-guide",
          "text-height": "text-xl sm:text-md"
        }, null, 8, ["item-text"]),
        _createVNode(_component_SidebarItem, {
          "item-text": _ctx.$t('nav.Sidebar.Faq'),
          "css-class": "flex-1",
          link: "/faq",
          "text-height": "text-xl sm:text-md"
        }, null, 8, ["item-text"]),
        _createVNode(_component_SidebarItem, {
          "item-text": _ctx.$t('nav.Sidebar.Support'),
          "css-class": "flex-1",
          link: "/support",
          "text-height": "text-xl sm:text-md"
        }, null, 8, ["item-text"]),
        _createVNode(_component_SidebarItem, {
          "item-text": _ctx.$t('nav.Sidebar.Imprint'),
          "css-class": "flex-1",
          link: "/imprint",
          "text-height": "text-xl sm:text-md"
        }, null, 8, ["item-text"]),
        _createVNode(_component_SidebarItem, {
          "item-text": _ctx.$t('nav.Sidebar.Privacy'),
          "css-class": "flex-1",
          link: "/privacy",
          "text-height": "text-xl sm:text-md"
        }, null, 8, ["item-text"]),
        _createVNode(_component_SidebarItem, {
          "item-text": _ctx.$t('nav.Sidebar.Terms'),
          "css-class": "flex-1 md:mb-72",
          link: "/terms",
          "text-height": "text-xl sm:text-md"
        }, null, 8, ["item-text"])
      ], 2)
    ])
  ]))
}