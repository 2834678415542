
import DefaultIcon from "@/components/Icon/DefaultIcon.vue";
import SidebarItem from "@/components/Nav/Sidebar/SidebarItem.vue";
import {defineComponent, ref} from 'vue';

export default defineComponent({
	name: 'Sidebar',
	components: {DefaultIcon, SidebarItem},
	props: {},
	emits: [],
	setup() {
		const showMenu = ref(false);

		function toggleSidebar() {
			showMenu.value = !showMenu.value;
		}

		function open() {
			showMenu.value = true;
		}

		function close() {
			showMenu.value = false;
		}

		return {
			showMenu, toggleSidebar, open, close
		}

	},
	methods: {}
})


